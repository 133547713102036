import { createStore } from 'vuex'
import authModule, { AuthState } from './auth'
import imageModule, { ImageState } from './imageStore'
import controlModule, { ControlState } from './controlStore'

export interface RootState {
  auth: AuthState,
  image: ImageState,
  control: ControlState
}

export default createStore<RootState>({
  modules: {
    auth: authModule,
    image: imageModule,
    control: controlModule
  }
})
