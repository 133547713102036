import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/patterns',
    name: 'patterns',
    meta: { title: 'MM - Patterns' },
    component: () => import('@/views/PatternsView.vue')
  },
  {
    path: '/new-pattern',
    name: 'new-pattern',
    meta: { title: 'MM - New Pattern' },
    component: () => import('@/views/Image/NewPattern.vue')
  },
  {
    path: '/canvas',
    name: 'canvas',
    meta: { title: 'MM - Canvas' },
    component: () => import('@/views/Canvas/MainView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404/NotFound.vue')
  }
]

export default routes
