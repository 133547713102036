import { Module } from 'vuex'
import { RootState } from './index'
import { PALLETE_36 } from '@/constants/palletes'

export interface ImageState {
  image: File | null
  imageName: string
  imageUrl: string
  chartWidth: number
  chartHeight: number
  imageGrid: string[][],
  imageColors: string[]
  colorPallete: {
    color: string,
    name: string,
    number: number,
    total: number
  }[]
}

const imageModule: Module<ImageState, RootState> = {
  state: {
    image: null,
    imageName: '',
    imageUrl: '',
    chartWidth: 70,
    chartHeight: 70,
    imageGrid: [],
    imageColors: [],
    colorPallete: PALLETE_36
  },
  mutations: {
    setImage (state, image: File) {
      state.image = image
      state.imageName = image.name
      state.imageUrl = URL.createObjectURL(image)
    },
    setChartDimensions (state, { width, height }) {
      state.chartWidth = width
      state.chartHeight = height
    },
    setImageGrid (state, imageGrid) {
      state.imageGrid = imageGrid
    },
    setImageColors (state, imageColors) {
      state.imageColors = imageColors
    },
    setColorPallete (state, colorPallete) {
      state.colorPallete = colorPallete
    }
  },
  actions: {
    selectImage ({ commit }, image: File) {
      commit('setImage', image)
    },
    saveChartDimensions ({ commit }, dimensions) {
      commit('setChartDimensions', dimensions)
    },
    saveImageGrid ({ commit }, imageGrid) {
      commit('setImageGrid', imageGrid)
    },
    saveImageColors ({ commit }, imageColors) {
      commit('setImageColors', imageColors)
    },
    saveColorPallete ({ commit }, colorPallete) {
      commit('setColorPallete', colorPallete)
    }
  },
  getters: {
    getImage: (state) => state.image,
    getImageDetails: (state) => ({
      imageName: state.imageName,
      imageUrl: state.imageUrl,
      chartWidth: state.chartWidth,
      chartHeight: state.chartHeight
    }),
    getImageGrid: (state) => state.imageGrid,
    getImageColors: (state) => state.imageColors,
    getColorPallete: (state) => state.colorPallete,
    getColorInfo: (state) => (color: string) => {
      return state.colorPallete.find(c => c.color.toLowerCase() === color.toLowerCase())
    }
  }
}

export default imageModule
