import { Module } from 'vuex'
import { RootState } from './index'

export interface AuthState {
  isAuthenticated: boolean
}

const authModule: Module<AuthState, RootState> = {
  state: {
    isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated') || 'false')
  },
  mutations: {
    setAuthenticated (state, value: boolean) {
      state.isAuthenticated = value
      localStorage.setItem('isAuthenticated', JSON.stringify(value))
    }
  },
  actions: {
    login ({ commit }, password: string) {
      if (localStorage.getItem('isAuthenticated') === 'true') {
        commit('setAuthenticated', true)
        return true
      }
      const hardcodedPassword = 'password'
      if (password === hardcodedPassword) {
        commit('setAuthenticated', true)
        return true
      }
      return false
    },
    logout ({ commit }) {
      commit('setAuthenticated', false)
    }
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated
  }
}

export default authModule
