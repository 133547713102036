<template>
  <router-view></router-view>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const isAuthenticated = computed(() => store.getters.isAuthenticated)

    onMounted(() => {
      if (!isAuthenticated.value) {
        router.push('/')
      }
    })
  }
}
</script>
