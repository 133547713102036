import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '../store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    document.title = 'Mystery Mosaics'
    next('/')
  } else {
    if (to.meta.title && typeof to.meta.title === 'string') {
      document.title = to.meta.title
    } else {
      document.title = 'Mystery Mosaics'
    }
    next()
  }
})

export default router
