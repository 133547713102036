const PALLETE_12 = [
  { color: '#FFFFFF', name: 'White', number: 0, total: 0 },
  { color: '#F5E956', name: 'Yellow', number: 1, total: 0 },
  { color: '#F39B3D', name: 'Orange', number: 2, total: 0 },
  { color: '#DE291B', name: 'Red', number: 3, total: 0 },
  { color: '#F245A1', name: 'Pink', number: 4, total: 0 },
  { color: '#9778B6', name: 'Purple', number: 5, total: 0 },
  { color: '#8BA7D6', name: 'Light Blue', number: 6, total: 0 },
  { color: '#4E7BBF', name: 'Dark Blue', number: 7, total: 0 },
  { color: '#9DC133', name: 'Light Green', number: 8, total: 0 },
  { color: '#018465', name: 'Dark Green', number: 9, total: 0 },
  { color: '#74462B', name: 'Brown', number: 10, total: 0 },
  { color: '#868B8E', name: 'Gray', number: 11, total: 0 },
  { color: '#000000', name: 'Black', number: 12, total: 0 }
]

const PALLETE_24 = [
  { color: '#FFFFFF', name: 'White', number: 0, total: 0 },
  { color: '#F5E956', name: 'Yellow', number: 1, total: 0 },
  { color: '#FFD302', name: 'Dark Yellow', number: 2, total: 0 },
  { color: '#F7B84B', name: 'Tan', number: 3, total: 0 },
  { color: '#F39B3D', name: 'Light Orange', number: 4, total: 0 },
  { color: '#E85B11', name: 'Orange', number: 5, total: 0 },
  { color: '#DE291B', name: 'Red', number: 6, total: 0 },
  { color: '#A11F35', name: 'Dark Red', number: 7, total: 0 },
  { color: '#F8B5C6', name: 'Light Pink', number: 8, total: 0 },
  { color: '#F245A1', name: 'Pink', number: 9, total: 0 },
  { color: '#9778B6', name: 'Purple', number: 10, total: 0 },
  { color: '#5B028F', name: 'Dark Purple', number: 11, total: 0 },
  { color: '#8BA7D6', name: 'Light Blue', number: 12, total: 0 },
  { color: '#4E7BBF', name: 'Blue', number: 13, total: 0 },
  { color: '#1B3F8D', name: 'Dark Blue', number: 14, total: 0 },
  { color: '#9DC133', name: 'Light Green', number: 15, total: 0 },
  { color: '#0AA137', name: 'Green', number: 16, total: 0 },
  { color: '#018465', name: 'Dark Green', number: 17, total: 0 },
  { color: '#B97430', name: 'Light Brown', number: 18, total: 0 },
  { color: '#74462B', name: 'Brown', number: 19, total: 0 },
  { color: '#614344', name: 'Dark Brown', number: 20, total: 0 },
  { color: '#868B8E', name: 'Gray', number: 21, total: 0 },
  { color: '#58595D', name: 'Dark Gray', number: 22, total: 0 },
  { color: '#000000', name: 'Black', number: 23, total: 0 }
]

const PALLETE_36 = [
  { color: '#FFFFFF', name: 'White', number: 0, total: 0 },
  { color: '#F5EC97', name: 'Light yellow', number: 1, total: 0 },
  { color: '#F5E956', name: 'Yellow', number: 2, total: 0 },
  { color: '#F9DD30', name: 'Dark Yellow', number: 3, total: 0 },
  { color: '#F7B84B', name: 'Tan', number: 4, total: 0 },
  { color: '#F39B3D', name: 'Light Orange', number: 5, total: 0 },
  { color: '#E85B11', name: 'Orange', number: 6, total: 0 },
  { color: '#E33419', name: 'Orange Red', number: 7, total: 0 },
  { color: '#DE291B', name: 'Red', number: 8, total: 0 },
  { color: '#B5261E', name: 'Deep Red', number: 9, total: 0 },
  { color: '#A11F35', name: 'Dark Red', number: 10, total: 0 },
  { color: '#F8B5C6', name: 'Light Pink', number: 11, total: 0 },
  { color: '#F245A1', name: 'Pink', number: 12, total: 0 },
  { color: '#B73A73', name: 'Dark Pink', number: 13, total: 0 },
  { color: '#9778B6', name: 'Purple', number: 14, total: 0 },
  { color: '#5B028F', name: 'Dark Purple', number: 15, total: 0 },
  { color: '#811E54', name: 'Plum', number: 16, total: 0 },
  { color: '#492B68', name: 'Dark Plum', number: 17, total: 0 },
  { color: '#1F2D41', name: 'Dark Blue', number: 18, total: 0 },
  { color: '#1B3F8D', name: 'Blue', number: 19, total: 0 },
  { color: '#4E7BBF', name: 'Light Blue', number: 20, total: 0 },
  { color: '#8BA7D6', name: 'Sky Blue', number: 21, total: 0 },
  { color: '#0096D7', name: 'Aqua Blue', number: 22, total: 0 },
  { color: '#018DB4', name: 'Teal Blue', number: 23, total: 0 },
  { color: '#009FB1', name: 'Turquoise', number: 24, total: 0 },
  { color: '#018465', name: 'Dark Green', number: 25, total: 0 },
  { color: '#039259', name: 'Sage Green', number: 26, total: 0 },
  { color: '#9DC133', name: 'Light Green', number: 27, total: 0 },
  { color: '#0AA137', name: 'Green', number: 28, total: 0 },
  { color: '#95AE3F', name: 'Olive Green', number: 29, total: 0 },
  { color: '#B97430', name: 'Light Brown', number: 30, total: 0 },
  { color: '#74462B', name: 'Brown', number: 31, total: 0 },
  { color: '#614344', name: 'Dark Brown', number: 32, total: 0 },
  { color: '#868B8E', name: 'Gray', number: 33, total: 0 },
  { color: '#58595D', name: 'Dark Gray', number: 34, total: 0 },
  { color: '#000000', name: 'Black', number: 35, total: 0 }
]

export { PALLETE_12, PALLETE_24, PALLETE_36 }
