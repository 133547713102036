import { Module } from 'vuex'
import { RootState } from './index'

interface ColorInfo {
  color: string;
  name: string;
  number: number;
  total: number;
}

export interface ControlState {
  selectedTool: string
  selectedColor: ColorInfo
  viewMode: string
}

const controlModule: Module<ControlState, RootState> = {
  state: {
    selectedTool: 'paint',
    viewMode: 'Flat',
    selectedColor: { color: '#FFFFFF', name: 'White', number: 0, total: 0 }
  },
  mutations: {
    setSelectedTool (state, tool: string) {
      state.selectedTool = tool
    },
    setSelectedColor (state, color: ColorInfo) {
      state.selectedColor = color
    },
    setViewMode (state, mode: string) {
      state.viewMode = mode
    }
  },
  actions: {
    selectTool ({ commit }, tool: string) {
      commit('setSelectedTool', tool)
    },
    selectColor ({ commit }, color: ColorInfo) {
      commit('setSelectedColor', color)
    },
    setViewMode ({ commit }, mode: string) {
      commit('setViewMode', mode)
    }
  },
  getters: {
    getSelectedTool: (state) => state.selectedTool,
    getSelectedColor: (state) => state.selectedColor,
    getViewMode: (state) => state.viewMode
  }
}

export default controlModule
